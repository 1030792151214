var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table init-table"},[_c('tr',[_c('th',[_vm._v("Engine")]),_c('th',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Lower is better'),expression:"'Lower is better'"}]},[_vm._v("Execution time")]),_c('th',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Lower is better'),expression:"'Lower is better'"}]},[_vm._v("CPU LOAD (cores)")]),_c('th',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Lower is better'),expression:"'Lower is better'"}]},[_vm._v("RAM")]),_c('th',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Lower is better'),expression:"'Lower is better'"}]},[_vm._v("IO (total)")])]),_vm._l((_vm.content),function(row,key){return [_c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(row.engine_name)+_vm._s(row.type.length > 0 ? "_" + row.type : "")+":"+_vm._s(row.version)+" ")]),_c('td',[_c('span',{class:{
          'text-green': row.init_time === _vm.minExecutionTime,
          'text-red': row.init_time === _vm.maxExecutionTime
        }},[_vm._v(" "+_vm._s(_vm.formatHumanReadableTime(parseInt(row.init_time)))+" ")])]),_c('td',[_c('ul',[_c('li',{class:{
              'text-green': row.metrics.cpu.average === _vm.minCPUAvg,
              'text-red': row.metrics.cpu.average === _vm.maxCPUAvg
            }},[_c('span',{staticClass:"avg"},[_vm._v("Average:")]),_vm._v(" "+_vm._s(row.metrics.cpu.average)+" ")]),_c('li',{class:{
              'text-green': row.metrics.cpu.median === _vm.minCPUMedian,
              'text-red': row.metrics.cpu.median === _vm.maxCPUMedian
            }},[_c('span',{staticClass:"median"},[_vm._v("Median:")]),_vm._v(" "+_vm._s(row.metrics.cpu.median)+" ")]),_c('li',{class:{
            'text-green': row.metrics.cpu['95p'] === _vm.minCPU95p,
            'text-red': row.metrics.cpu['95p'] === _vm.maxCPU95p
          }},[_c('span',{staticClass:"percentile"},[_vm._v("95 percentile:")]),_c('span',[_vm._v(" "+_vm._s(row.metrics.cpu["95p"])+" ")])])])]),_c('td',[_c('ul',[_c('li',{class:{
              'text-green': row.metrics.ram.average === _vm.minRAMAvg,
              'text-red': row.metrics.ram.average === _vm.maxRAMAvg
            }},[_c('span',{staticClass:"avg"},[_vm._v("Average:")]),_vm._v(" "+_vm._s(_vm.formatHumanReadableMegaBytes(row.metrics.ram.average))+" ")]),_c('li',{class:{
              'text-green': row.metrics.ram.median === _vm.minRAMMedian,
              'text-red': row.metrics.ram.median === _vm.maxRAMMedian
            }},[_c('span',{staticClass:"median"},[_vm._v("Median:")]),_vm._v(" "+_vm._s(_vm.formatHumanReadableMegaBytes(row.metrics.ram.median))+" ")]),_c('li',{class:{
              'text-green': row.metrics.ram['95p'] === _vm.minRAM95p,
              'text-red': row.metrics.ram['95p'] === _vm.maxRAM95p
            }},[_c('span',{staticClass:"percentile"},[_vm._v("95 percentile:")]),_vm._v(" "+_vm._s(_vm.formatHumanReadableMegaBytes(row.metrics.ram["95p"]))+" ")])])]),_c('td',[_c('ul',[(row.metrics.disc.read === undefined)?[_c('li',[_c('span',{staticClass:"avg"},[_vm._v("Total (r+w):")]),_vm._v(" "+_vm._s(_vm.formatHumanReadableMegaBytes(row.metrics.disc.total)))])]:[_c('li',{class:{
                'text-green': row.metrics.disc.read.total === _vm.minIORead,
                'text-red': row.metrics.disc.read.total === _vm.maxIORead
              }},[_c('span',{staticClass:"avg"},[_vm._v("Read:")]),_vm._v(" "+_vm._s(_vm.formatHumanReadableMegaBytes(row.metrics.disc.read.total))+" ")]),_c('li',{class:{
                'text-green': row.metrics.disc.write.total === _vm.minIOWrite,
                'text-red': row.metrics.disc.write.total === _vm.maxIOWrite
              }},[_c('span',{staticClass:"avg"},[_vm._v("Write:")]),_vm._v(" "+_vm._s(_vm.formatHumanReadableMegaBytes(row.metrics.disc.write.total))+" ")])]],2)])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }